import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-experiences',
    templateUrl: './experiences.component.html',
    styleUrls: ['./experiences.component.scss']
})
export class ExperiencesComponent implements OnInit {
    progress = 0;

    ngOnInit() {
        setInterval(() => {
            if (this.progress < 87) {
                this.progress++;
            }
        }, 8);
    }

}
