import { Component } from '@angular/core';

@Component({
    selector: 'app-culture',
    templateUrl: './culture.component.html',
    styleUrls: ['./culture.component.scss']
})
export class CultureComponent {
    basePath = '../../assets/img/culture/';
    musics = [
        {label: 'Max Cooper', file: 'max.jpg'},
        {label: 'Gorillaz', file: 'gorillaz.jpg'},
        {label: 'Isaac Delusion', file: 'isaac.jpg'},
        {label: 'Jamiroquai', file: 'jamiroquai.jpg'},
        {label: 'Felkon', file: 'felkon.jpg'},
    ];
    movies = [
        {label: 'Dog Pound', file: 'dog-pound.jpg'},
        {label: 'Princesse Mononoké', file: 'princesse-mononoke.jpg'},
        {label: 'Vanilla Sky', file: 'vanilla-sky.jpg'},
        {label: 'Snatch', file: 'snatch.jpg'},
        {label: 'Good Morning England', file: 'gme.jpg'},
    ];
    activities = [
        {label: 'Escalade', file: 'escalade.jpg'},
        {label: 'Tennis', file: 'tennis.jpg'},
        {label: 'Natation', file: 'natation.jpg'},
        {label: 'Volleyball', file: 'volleyball.jpg'},
        {label: 'Vélo', file: 'velo.jpg'},
    ];
    games = [
        {label: 'League Of Legends', file: 'lol.jpg'},
        {label: 'Minecraft', file: 'minecraft.jpg'},
        {label: 'Ragnarok Online', file: 'ro.jpg'},
        {label: 'Diablo 3', file: 'diablo.jpg'},
        {label: 'Fortnite', file: 'fortnite.jpg'},
    ];
}
