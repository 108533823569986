import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ExperiencesComponent } from './experiences/experiences.component';
import { SkillsComponent } from './skills/skills.component';
import { TrainingsAndDiplomasComponent } from './trainings-and-diplomas/trainings-and-diplomas.component';
import { CultureComponent } from './culture/culture.component';

const appRoutes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'experiences', component: ExperiencesComponent },
    { path: 'skills', component: SkillsComponent },
    { path: 'trainings-and-diplomas', component: TrainingsAndDiplomasComponent },
    { path: 'culture', component: CultureComponent },
    { path: '',   redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
