import { Component } from '@angular/core';

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss']
})
export class SkillsComponent {
    beSkills = [
        { label: 'C++', progress: 75 },
        { label: 'Qt5 (Core & SQL)', progress: 85 },
        { label: 'CMake', progress: 70 },
        { label: 'PHP', progress: 80 },
        { label: 'Symfony', progress: 85 },
        { label: 'Node.js', progress: 80 },
        { label: 'PHPUnit', progress: 75 },
        { label: 'RabbitMQ', progress: 55 },
        { label: 'Java', progress: 70 },
        { label: 'Python', progress: 65 },
        { label: 'Django', progress: 55 },
        { label: 'Go', progress: 60 },
        { label: 'Ruby', progress: 80 },
        { label: 'Ruby on Rails', progress: 55 },
    ];
    feSkills = [
        { label: 'Angular 8+', progress: 90 },
        { label: 'Angular JS', progress: 80 },
        { label: 'Vue.js', progress: 70 },
        { label: 'Redux', progress: 80 },
        { label: 'RxJS', progress: 90 },
        { label: 'TypeScript', progress: 90 },
        { label: 'jQuery', progress: 90 },
        { label: 'HTML', progress: 95 },
        { label: 'CSS', progress: 95 }
    ];
    dbSkills = [
        { label: 'Oracle', progress: 90 },
        { label: 'PostgreSQL', progress: 85 },
        { label: 'MySQL', progress: 85 },
        { label: 'MongoDB', progress: 60 },
        { label: 'Redis', progress: 75 },
        { label: 'SQLite', progress: 80 },
        { label: 'Doctrine', progress: 75 },
        { label: 'MariaDB', progress: 70 }
    ];
    osSkills = [
        { label: 'Windows', progress: 95 },
        { label: 'UNIX', progress: 85 }
    ];
    vcsSkills = [
        { label: 'Git', progress: 90 },
        { label: 'TortoiseSVN', progress: 40 }
    ];
    othersSkills = [
        { label: 'Ionic', progress: 70 },
        { label: 'Electron', progress: 60 },
        { label: 'Ansible', progress: 70 },
        { label: 'GitLab CI/CD', progress: 30 },
        { label: 'TeamCity', progress: 75 },
        { label: 'OpenStack', progress: 90 },
    ]
}
