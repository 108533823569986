<div class="experiences">
    <div class="lifeline" [style.height.%]="progress"></div>
    <div class="experience clearfix">
        <div class="right">
            <div class="bull"></div>
            <p class="date">{{'exp.syn.length'|translate}}</p>
            <h3>Synlab / Novalis</h3>
            <h4>{{'exp.syn.title'|translate}}</h4>
            <p class="paragraph">
                &bull; {{'exp.syn.p1'|translate}}<br>
                &bull; {{'exp.syn.p2'|translate}}<br>
                &bull; {{'exp.syn.p3'|translate}}<br>
                &bull; {{'exp.syn.p4'|translate}}<br>
            </p>
            <h4>{{'exp.stack'|translate}}</h4>
            <ul>
                <li>C++</li>
                <li>Qt5 (Core & SQL)</li>
                <li>CMake</li>
                <li>Angular</li>
                <li>Node.js</li>
                <li>Oracle</li>
            </ul>
        </div>
    </div>
    <div class="experience clearfix">
        <div class="left">
            <div class="bull"></div>
            <p class="date">{{'exp.me.length'|translate}}</p>
            <h3>{{'exp.me.mainTitle'|translate}}</h3>
            <h4>{{'exp.me.title'|translate}}</h4>
            <p class="paragraph">
                &bull; {{'exp.me.p1'|translate}} <a href="http://tifanydelgrange-architectures.fr/">http://tifanydelgrange-architectures.fr/</a><br>
                &bull; {{'exp.me.p2'|translate}}<br>
                &bull; {{'exp.me.p3'|translate}}<br>
                &bull; {{'exp.me.p4'|translate}}<br>
                &bull; {{'exp.me.p5'|translate}}<br>
            </p>
            <h4>{{'exp.stack'|translate}}</h4>
            <ul>
                <li>Electron</li>
                <li>Ionic</li>
                <li>Angular</li>
                <li>Symfony</li>
                <li>SQLite</li>
            </ul>
        </div>
    </div>
    <div class="experience clearfix">
      <div class="right">
          <div class="bull"></div>
          <p class="date">{{'exp.ubi.length'|translate}}</p>
          <h3>Ubisoft Québec</h3>
          <h4>{{'exp.ubi.title'|translate}}</h4>
          <p class="paragraph">
              &bull; {{'exp.ubi.p1'|translate}}<br>
              &bull; {{'exp.ubi.p2'|translate}}<br>
              &bull; {{'exp.ubi.p3'|translate}}<br>
          </p>
          <h4>{{'exp.stack'|translate}}</h4>
          <ul>
              <li>Python</li>
              <li>Go</li>
              <li>Java</li>
              <li>Ruby</li>
              <li>Ansible</li>
              <li>OpenStack</li>
              <li>MariaDB</li>
              <li>GitLab CI/CD</li>
              <li>TeamCity</li>
          </ul>
      </div>
    </div>
    <div class="experience clearfix">
        <div class="left">
            <div class="bull"></div>
            <p class="date">{{'exp.wow.length'|translate}}</p>
            <h3>Wake on web</h3>
            <h4>{{'exp.wow.title'|translate}}</h4>
            <p class="paragraph">
                &bull; {{'exp.wow.p1'|translate}}<br>
                &bull; {{'exp.wow.p2'|translate}}<br>
                &bull; {{'exp.wow.p3'|translate}}<br>
                &bull; {{'exp.wow.p4'|translate}}<br>
                &bull; {{'exp.wow.p5'|translate}}<br>
            </p>
            <h4>{{'exp.stack'|translate}}</h4>
            <ul>
                <li>AngularJS & 2+</li>
                <li>Typescript</li>
                <li>RxJS</li>
                <li>Redux</li>
                <li>PHP 5.6 & 7+</li>
                <li>Symfony 2 & 3</li>
                <li>Twig</li>
                <li>PostgreSQL</li>
                <li>Redis</li>
                <li>RabbitMQ</li>
                <li>Git</li>
                <li>PHPUnit</li>
                <li>Jenkins</li>
            </ul>
        </div>
    </div>
    <div class="experience clearfix">
        <div class="right">
            <div class="bull"></div>
            <p class="date">{{'exp.shorg.length'|translate}}</p>
            <h3>Shorg</h3>
            <h4>{{'exp.shorg.title'|translate}}</h4>
            <p class="paragraph">
                &bull; {{'exp.shorg.p1'|translate}}<br>
                &bull; {{'exp.shorg.p2'|translate}}<br>
                &bull; {{'exp.shorg.p3'|translate}}<br>
            </p>
            <h4>{{'exp.stack'|translate}}</h4>
            <ul>
                <li>PHP 5.6</li>
                <li>Symfony 2</li>
                <li>Twig</li>
                <li>jQuery</li>
                <li>MongoDB</li>
            </ul>
        </div>
    </div>
    <div class="experience clearfix">
        <div class="left">
            <div class="bull"></div>
            <p class="date">{{'exp.oxy.length'|translate}}</p>
            <h3>Oxytronic</h3>
            <h4>{{'exp.oxy.title'|translate}}</h4>
            <p class="paragraph">
                &bull; {{'exp.oxy.p1'|translate}}<br>
                &bull; {{'exp.oxy.p2'|translate}}<br>
                &bull; {{'exp.oxy.p3'|translate}}<br>
                &bull; {{'exp.oxy.p4'|translate}}<br>
            </p>
        </div>
    </div>
</div>