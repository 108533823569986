import { Component } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-locale',
  template: `
    <div [class.selected]="locale == 'en'" (click)="locale = 'en'"><img src="../../assets/img/en.png" alt="English language"></div>
    <div [class.selected]="locale == 'fr'" (click)="locale = 'fr'"><img src="../../assets/img/fr.png" alt="Langue Française"></div>
  `,
  styles: [`
    :host {
      position: absolute;
      top: 6px;
      right: 0;
    }

    :host div {
      position: relative;
      display: inline-block;
      margin: 0 5px;
      cursor: pointer;
    }

    :host div::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #0000007a;
      content: " ";
    }

    :host div.selected::after {
      background-color: inherit;
    }
  `]
})
export class LocaleComponent {
  private _locale = 'fr';

  get locale(): string {
    return this._locale;
  }

  set locale(l: string) {
    this._locale = l;
    this.translate.use(l);
  }

  constructor(private translate: TranslateService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang(this.locale);

    const browserLang = translate.getBrowserLang();
    this.locale = browserLang.match(/en|fr/) ? browserLang : 'en';
  }
}