<div class="home">
    <div class="side clearfix">
        <p><span class="dot" [class.red]="!inActiveSearch">&nbsp;</span> {{getLfoLabel()}}</p>
        <p>{{getDateSince('1993/12/15')}} {{'home.age'|translate}}</p>
        <p>{{'home.location'|translate}}</p>
        <p>+33 6 46 53 07 80</p>
        <p><a href="mailto:contact@charlely-delgrange.fr">Email</a></p>
        <p><a [href]="'home.cvPath'|translate" target="_blank"><img src="../../assets/img/pdf-ico.png" alt="pdf icon">{{'home.cv'|translate}}</a></p>
    </div>
    <div class="description clearfix">
        <img src="../../assets/img/profile.jpg" alt="profile">
        <h2>Delgrange Charlély</h2>
        <h3>{{'home.title'|translate}}</h3>
        <p class="paragraph">
            {{'home.p1-1'|translate}}
            {{getDateSince('2014/01/01')}}
            <span [innerHTML]="'home.p1-2'|translate"></span>
        </p>
        <br>
        <p class="paragraph">{{'home.p2'|translate}}</p>
        <p class="paragraph">{{'home.p3'|translate}}</p>
        <br>
        <p class="paragraph">{{'home.p4'|translate}}</p>
    </div>
</div>