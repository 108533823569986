import { LocaleComponent } from './locale/locale.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { SkillsComponent } from './skills/skills.component';
import { ExperiencesComponent } from './experiences/experiences.component';
import { TrainingsAndDiplomasComponent } from './trainings-and-diplomas/trainings-and-diplomas.component';
import { AppRoutingModule } from './app-routing.module';
import { CultureComponent } from './culture/culture.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        HomeComponent,
        SkillsComponent,
        ExperiencesComponent,
        TrainingsAndDiplomasComponent,
        CultureComponent,
        ProgressBarComponent,
        LocaleComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
