import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
    @Input() skill: {label: string, progress: number} = {label: '', progress: 0};
    progress = 0;

    constructor() { }

    ngOnInit() {
        setInterval(() => {
            if (this.progress < this.skill.progress) {
                this.progress++;
            }
        }, 8);
    }

}
