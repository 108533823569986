<div class="tad">
    <ul>
        <li>
            <h3>{{'trainings.2017'|translate}}</h3>
            <p class="origin"><span class="date">2017</span> {{'trainings.by'|translate}} Quentin Schuler, WakeOnWeb</p>
        </li>
        <li class="separator">-</li>
        <li>
            <h3>{{'trainings.2016'|translate}}</h3>
            <p class="origin"><span class="date">2016</span> {{'trainings.by'|translate}} Jérémy Romey, Sensio Labs</p>
        </li>
        <li class="separator">-</li>
        <li>
            <h3>{{'trainings.2015'|translate}}</h3>
            <p class="origin"><span class="date">2015</span> {{'trainings.by'|translate}} Hugo Hamon, Sensio Labs</p>
        </li>
        <li class="separator">-</li>
        <li>
            <h3>{{'trainings.2014'|translate}}</h3>
            <p class="origin"><span class="date">2014</span> Marseille, France</p>
        </li>
        <li class="separator">-</li>
        <li>
            <h3>{{'trainings.2013'|translate}} <span class="more">{{'trainings.2013More'|translate}}</span></h3>
            <p class="origin"><span class="date">2013</span> Marseille, France</p>
        </li>
        <li class="separator">-</li>
        <li>
            <h3>{{'trainings.2011'|translate}} <span class="more">{{'trainings.2011More'|translate}}</span></h3>
            <p class="origin"><span class="date">2011</span> Gardanne, France</p>
        </li>
    </ul>
</div>