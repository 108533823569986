import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    bgRandomPosition = '0';

    constructor(private router: Router) { }

    ngOnInit() {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.bgRandomPosition = `-${Math.floor(Math.random() * Math.floor(window.innerWidth * 1969 / 3500 - 175))}px`;
            }
        });
    }

}
