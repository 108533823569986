<div class="col clearfix">
    <h2>{{'culture.musics'|translate}}</h2>
    <ul>
        <li *ngFor="let music of musics">
            <img [src]="basePath + music.file">
            <h6>{{music.label}}</h6>
        </li>
    </ul>
</div>
<div class="col clearfix">
    <h2>{{'culture.movies'|translate}}</h2>
    <ul>
        <li *ngFor="let movie of movies">
            <img [src]="basePath + movie.file">
            <h6>{{movie.label}}</h6>
        </li>
    </ul>
</div>
<div class="col clearfix">
    <h2>{{'culture.sports'|translate}}</h2>
    <ul>
        <li *ngFor="let activity of activities">
            <img [src]="basePath + activity.file">
            <h6>{{activity.label}}</h6>
        </li>
    </ul>
</div>
<div class="col clearfix">
    <h2>{{'culture.videoGames'|translate}}</h2>
    <ul>
        <li *ngFor="let game of games">
            <img [src]="basePath + game.file">
            <h6>{{game.label}}</h6>
        </li>
    </ul>
</div>