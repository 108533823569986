import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    inActiveSearch = true;

    constructor(private translate: TranslateService) {}

    getLfoLabel(): string {
        let label = '';

        this.translate.get(this.inActiveSearch ? 'home.lfoOn' : 'home.lfoOff').subscribe(val => {
            label = val;
        });

        return label;
    }

    getDateSince(input: string): number {
        const today = new Date();
        const birthDate = new Date(input);

        let output = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            output--;
        }

        return output;
    }
}
