<div class="col col-1 clearfix">
    <h2>Back-End</h2>
    <ul class="skills">
        <li *ngFor="let skill of beSkills"><app-progress-bar [skill]="skill"></app-progress-bar></li>
    </ul>
    <h2>{{'skills.os'|translate}}</h2>
    <ul class="skills">
        <li *ngFor="let skill of osSkills"><app-progress-bar [skill]="skill"></app-progress-bar></li>
    </ul>
</div>
<div class="col col-2 clearfix">
    <h2>Front-End</h2>
    <ul class="skills">
        <li *ngFor="let skill of feSkills"><app-progress-bar [skill]="skill"></app-progress-bar></li>
    </ul>
    <h2>Versioning</h2>
    <ul class="skills">
        <li *ngFor="let skill of vcsSkills"><app-progress-bar [skill]="skill"></app-progress-bar></li>
    </ul>
</div>
<div class="col col-3 clearfix">
    <h2>{{'skills.db'|translate}}</h2>
    <ul class="skills">
        <li *ngFor="let skill of dbSkills"><app-progress-bar [skill]="skill"></app-progress-bar></li>
    </ul>
    <h2>{{'skills.else'|translate}}</h2>
    <ul class="skills">
        <li *ngFor="let skill of othersSkills"><app-progress-bar [skill]="skill"></app-progress-bar></li>
    </ul>
</div>